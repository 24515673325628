import { UserPage } from "../../../js/Base/UserPage";
import { OzTicketCategory } from "../../../js/Domain/OzTicketCategory";

export class CategoryClassAction extends UserPage {
  lock = [];
  GetTicketClassByCategoryNo = (category_no, success_callback = null, error_callback = null) => async (disptch, getState) => {
    if (!this.lock.find((l) => l === category_no)) {
      if (!getState().categoryClass.category_class_by_category[category_no]) {
        this.lock = [...this.lock, category_no];
        await this.WebApi(
          "ticket/setting",
          "FindTicketClassByCategoryNo",
          { ticket_category_no: category_no },
          (res) => {
            disptch({ type: "GetTicketClassByCategoryNo", classes: res.data.data, category_no });
            if (success_callback) success_callback(res.data.data);
          },
          (err) => {
            disptch({ type: "Error", payload: err });
            if (error_callback) error_callback(err)
          }
        );
        this.lock = this.lock.filter((l) => l !== category_no);
      }
    }
  };

  GetCategoryByNo = (category_no) => async (disptch, getState) => {
    const category = getState().categoryClass.categories.find((c) => c.no === category_no);

    if (!category) {
      await this.WebApi(
        "ticket/setting",
        "FindTicketCategoryByNo",
        { category_no },
        (res) => {
          const category = new OzTicketCategory().fromDict(res.data.data);
          disptch({ type: "GetCategoryByNo", category });
        },
        (err) => {
          disptch({ type: "Error", payload: err });
        }
      );
    }
  };

  GetCategoryByProjectNo =
    (project_no, success_callback = null, error_callback = null) =>
    async (disptch, getState) => {
      // if (!getState().categoryClass.category_by_project_no[project_no]) {
      await this.WebApi(
        "ticket/setting",
        "FindTicketCategoryByProjectNo",
        { project_no },
        (res) => {
          const categories = res.data.data.items.map((c) => new OzTicketCategory().fromDict(c));
          // console.log(categories)
          disptch({ type: "GetCategoryByProjectNo", categories: categories.filter((c) => !c.is_delete), project_no });
          if (success_callback) success_callback(res.data.data.items);
        },
        (err) => {
          disptch({ type: "Error", payload: err });
          if (error_callback) error_callback(err);
        }
      );
      // }
      return null;
    };

  GetTicketTemplateByCategoryNoClassNo = (category_no, class_no) => async (disptch, getState) => {
    await this.WebApi(
      "ticket/setting",
      "FindTicketTemplateByCategoryNoClassNo",
      { category_no, class_no },
      async (res) => {
        await disptch({
          type: "GetTicketTemplateByCategoryNoClassNo",
          template_data: res.data.data,
          category_no,
          class_no,
        });
      },
      (err) => {
        disptch({ type: "Error", payload: err });
      }
    );
  };

  GetTicketTemplateDataByCategoryNoClassNo = (category_no, class_no) => async (disptch, getState) => {
    this.__GetTicketTemplateDataByCategoryNoClassNo(category_no, class_no, disptch, getState);
  };

  __GetTicketTemplateDataByCategoryNoClassNo = async (category_no, class_no, disptch, getState) => {
    await this.WebApi(
      "ticket/setting",
      "FindTicketTemplateDataByCategoryNoClassNo",
      { category_no, class_no },
      (res) => {
        disptch({
          type: "GetTicketTemplateDataByCategoryNoClassNo",
          template_data: res.data.data,
          category_no,
          class_no,
        });
      },
      (err) => {
        disptch({ type: "Error", payload: err });
      }
    );
  };

  SaveTicketTemplateDataByCategory = (category_no, class_no, ticket_template_no) => async (disptch, getState) => {
    class_no = class_no ? class_no : null;
    await this.WebApi(
      "ticket/setting",
      "SaveTicketTemplateDataByCategory",
      { category_no, class_no, ticket_template_no },
      (res) => {
        // console.log(res)
        // console.log(res.data.data.items)
        // disptch({ type: 'SaveTicketTemplateDataByCategory', template_data: res.data.data, category_no, class_no })
        if (res.data.data === true)
          disptch({ type: "GetTicketTemplateDataByCategoryNoClassNo", template_data: null, category_no, class_no });
        else
          disptch({
            type: "GetTicketTemplateDataByCategoryNoClassNo",
            template_data: res.data.data,
            category_no,
            class_no,
          });
      },
      (err) => {
        disptch({ type: "Error", payload: err });
      }
    );
  };
}
